<template>
  <div class="container mescroll-touch">
    <div class="main">
    <div class="inputBox">
      <span class="label">Area</span>
      <div class="RegionBox">
        <span class="RegionItem" :class="{'active': type == item.type}" v-for="(item, index) in RegionList" :key="index" @click="RegionChange(item.type)">{{item.name}}</span>
      </div>
    </div>
    <div class="inputBox">
      <span class="label">标题</span>
      <div class="box">
        <van-field
          class="textarea"
          v-model="title"
          rows="2"
          autosize
          type="textarea"
          placeholder="请输入视频标题..."
        />
      </div>
    </div>
    <div class="inputBox">
      <span class="label">话题</span>
      <div class="box">
        <van-field
          class="textarea"
          v-model="Topic"
          rows="2"
          autosize
          type="textarea"
          placeholder="请输入话题..."
        />
      </div>
      <div class="hotTopicBox">
        <span class="hotTopic_title">热门话题：</span>
        <span class="hotTopic" v-for="(item, index) in hotTopicList" :key="index" @click="selectTotTopic(item)">{{item}}</span>
      </div>
    </div>
    <div class="inputBox">
      <span class="label">视频</span>
      <span class="tips">（视频大小不能超过1GB）</span>
      <div class="box">
        <van-uploader accept="video/*" :after-read="afterRead" :max-size="1024 * 1024 * 1024" @oversize="onOversize" />
      </div>
      <video ref="video" id="myvideo" class="video" v-show="videoUrl" :src="videoUrl" preload="auto" controls></video>
      <canvas id="mycanvas" style="display: none"></canvas>
    </div>
    </div>
    <div class="submit" :class="{'submit1': !isClick}" @click="submit">{{isClick ? "发布" : "发布中"}}</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      RegionList: [{
        type: 1,
        name: '东区'
      }, {
        type: 2,
        name: '南区'
      }, {
        type: 3,
        name: '西区'
      }, {
        type: 4,
        name: '北区'
      }, {
        type: 5,
        name: 'CV南北'
      }, {
        type: 6,
        name: 'CV东西'
      }],
      type: 1,
      title: '',
      Topic: '',
      hotTopicList: ['#E起爱缝合注册医生数已经突破6W', '#敢赢竞技场'],
      videoUrl: '',
      Thumbnail: '',
      isClick: true
    }
  },
  methods: {
    RegionChange(type) {
      this.type = type;
    },
    selectTotTopic(hotTopic) {
      this.Topic += hotTopic + ' '
    },
    afterRead(files) {
      console.log('files------', files.file)
      // const video = document.createElement('video');
      // video.preload = 'metadata';
 
      // // 设置视频源
      // video.src = URL.createObjectURL(files.file);

      // let url = URL.createObjectURL(files.file);
	    //经测试，发现audio也可获取视频的时长
	    // let video = new Audio(url);
	    // audioElement.addEventListener("loadedmetadata", (_event) => {
	    //     this.audioDuration = parseInt(audioElement.duration);
	    //     console.log(this.audioDuration);
	    // });
 
      // 监听视频元素的loadedmetadata事件，获取视频时长
      // video.addEventListener('loadedmetadata', () => {
        // console.log('视频时长：', video.duration, '秒');
        // URL.revokeObjectURL(video.src); // 释放对象URL
        // if(video.duration > 600) {
        //   this.$toast('视频时长不能超过10分钟');
        // } else {
          const toast = this.$toast.loading({
            message: '上传中...',
            forbidClick: true,
            duration: 0
          });

          const formData = new FormData();
          formData.append("file", files.file);
          this.$axios.post('/Api/Api/Web/Files/VideoUpload', formData).then(res => {
            if (res.RetCode == '10000') {
              let data = res.Data;
              this.videoUrl = data.Url;
              // this.$refs.video.load();
              // this.generateDideoCover();
              toast.clear();
              this.$toast.success('上传成功');
            } else {
              this.$toast.fail('上传失败');
            }
          })
        // }
      // }, false);
    },
    onOversize() {
      this.$toast('视频大小不能超过1GB');
    },
    generateDideoCover() {
      const video = document.getElementById("myvideo"); // 获取视频对象
      video.load();
      // const video = document.createElement("video") // 也可以自己创建video
      // video.src = this.videoUrl; // url地址 url跟 视频流是一样的
      var canvas = document.getElementById("mycanvas"); // 获取 canvas 对象
      const ctx = canvas.getContext("2d"); // 绘制2d
      video.crossOrigin = "anonymous"; // 解决跨域问题，也就是提示污染资源无法转换视频
      video.currentTime = 1; // 第一帧
      video.oncanplay = () => {
        canvas.width = video.clientWidth; // 获取视频宽度
        canvas.height = video.clientHeight; //获取视频高度
        // 利用canvas对象方法绘图
        ctx.drawImage(video, 0, 0, video.clientWidth, video.clientHeight);
        // 转换成base64形式
        let Thumbnail = canvas.toDataURL("image/png"); // 截取后的视频封面
        let base64Img = Thumbnail.substring(Thumbnail.indexOf(',')+1);
        this.$axios.post('/Api/Api/Web/Files/Upload', {
          type: 0,
          fl: base64Img
        }).then(res => {
          if (res.RetCode == '10000') {
            let data = res.Data;
            this.Thumbnail = data.Url;
            this.AddArticle();
          } else {
            this.$toast.fail('封面生成失败');
          }
        })
      };
    },
    // async UploadThumbnail(base64Img) {
    //   let res = await this.$axios.post('/Api/Api/Web/Files/Upload', {
    //       type: 0,
    //       fl: base64Img
    //   });
    //   if (res.RetCode == '10000') {
    //     let data = res.Data;
    //     this.Thumbnail = data.Url;
    //   }
    // },
    async AddArticle() {
      let res = await this.$axios.post('/Api/Api/Web/Article/AddArt', {
        Type: this.type,
        Title: this.title,
        Topic: this.Topic,
        Video: this.videoUrl,
        Thumbnail: this.Thumbnail
      });
      if (res.RetCode == '10000') {
        this.$toast.success('提交成功');
        this.$router.push('/videoList');
        this.isClick = true;
        // this.$router.go(-1);
      } else {
        this.isClick = true;
        this.$toast.fail('提交失败');
      }
    },
    submit() {
      if(!this.title) {
        this.$toast('请填写视频标题！');
        return false;
      }

      if(!this.videoUrl) {
        this.$toast('请上传视频！');
        return false;
      }

      if(this.isClick) {
        this.isClick = false;
        this.generateDideoCover();
      }

      // this.$axios.post('/Api/Api/Web/Article/AddArt', {
      //   Type: this.type,
      //   Title: this.title,
      //   Topic: this.Topic,
      //   Video: this.videoUrl,
      //   Thumbnail: this.Thumbnail
      // }).then(res => {
      //   if (res.RetCode == '10000') {
      //     this.$toast.success('提交成功');
      //     this.$router.go(-1);
      //   } else {
      //     this.$toast.fail('提交失败');
      //   }
      // })
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
  box-sizing: border-box;
  .main {
    flex: 1;
  }
  .inputBox {
    margin-bottom: 15px;
    .label {
      font-size: 15px;
      color: #1f2f3d;
      line-height: 30px;
    }
    .RegionBox {
      display: flex;
      flex-wrap: wrap;
      .RegionItem {
        padding: 6px 12px;
        border-radius: 6px;
        border: 1px solid #bfbfbf;
        color: #bfbfbf;
        margin: 6px 10px 6px 0;
        font-size: 14px;
      }
      .active {
        border: 1px solid #EA1100;
        background-color: #EA1100;
        color: #FFFFFF;
      }
    }
    .box {
      margin-top: 8px;
    }
    .textarea {
      padding: 6px 10px;
      border-radius: 6px;
      border: 1px solid #bfbfbf;
    }
    .tips {
      font-size: 12px;
      color: #959595;
    }
  }
  .hotTopicBox {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-size: 13px;
    margin-top: 10px;
    .hotTopic_title {
      margin-bottom: 8px;
    }
    .hotTopic {
      padding: 8px 10px;
      background-color: #FAFAFA;
      border-radius: 6px;
      margin-right: 8px;
      margin-bottom: 8px;
    }
  }
  .video {
    width: 100%;
    margin-top: 10px;
  }
  .submit {
    // position: absolute;
    // bottom: 20px;
    // left: 50%;
    // transform: translateX(-50px);
    margin: 10px auto 0;
    width: 100px;
    height: 40px;
    line-height: 40px;
    border-radius: 40px;
    text-align: center;
    background-color: #EA1100;
    color: #FFFFFF;
  }
  .submit1 {
    opacity: 0.5;
  }
  .cover {
    width: 100%;
  }
}
</style>